import { appConfig } from "../appConfig";
import {
    AuthUserProfile,
    Municipality,
    ReplacementWindowResponse,
    TimeRestriction,
    User,
} from "../common/types";
import { PaymentCodes } from "../views/CustomerView/customerTypes";
import { Orderer, OrdererInput } from "../views/OrdererView/ordererTypes";
import { Unit, UnitInput } from "../views/UnitView/unitTypes";
import {
    CustomerBillingDTO,
    CustomerDTO,
    CustomerPaymentInfoDTO,
    GetCustomerOrdersDTO,
    PaginationDTO,
    UpdateCustomerBillingRequest,
    UpdateCustomerRequestBody,
} from "./apiTypes";
import {
    ensureHttpSuccess,
    fetchJSON,
    getAuthToken,
    parseJSON,
    sendJSON,
    withoutCache,
} from "./apiUtils";

const BASEURL = appConfig.API_URL;
const MATKONTO_CLIENTID = appConfig.MATKONTO_CLIENT_ID;

const getUrlWithApiVersion = (url: string, version: number) => {
    const tempUrl = new URL(url);
    tempUrl.searchParams.set("api-version", `v${version}`);
    return tempUrl.href;
};

const getUrlWithQueryString = (
    baseUrl: string,
    searchParams: Record<string, number | string | undefined>,
    version: number
) => {
    const params = new URLSearchParams();
    Object.entries(searchParams).forEach(([key, value]) => {
        if (value !== undefined) {
            params.set(key, value.toString());
        }
    });
    params.set("api-version", `v${version}`);
    return baseUrl + "?" + params.toString();
};

export const municipalityMethods = (municipality: string) => {
    const authToken = getAuthToken();

    return {
        getAvailablePaymentMethods: () =>
            !authToken?.length
                ? Promise.reject("Can't get payment methods. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/paymentmethods`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              paymentModes: CustomerPaymentInfoDTO[];
                          }>
                      ),
        getMunicipalityRestrictions: () =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get municipality restrictions. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/restrictions`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              homeTimeWindowRestrictions: TimeRestriction[];
                          }>
                      ),
        getMunicipality: () =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get municipality restrictions. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<Municipality>),
    };
};

export const userMethods = () => {
    const authToken = getAuthToken();

    return {
        getUser: (userId: string) =>
            !authToken?.length
                ? Promise.reject("Can't get user details. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(`${BASEURL}/users/${userId}`, 1),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<User>),
        getAuthenticatedUserProfile: () =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get user profile details. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipalities/access/info`, // APIM url missing
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<AuthUserProfile>),
    };
};

export const unitMethods = (municipality: string) => {
    const authToken = getAuthToken();

    return {
        getAllUnits: () =>
            !authToken?.length
                ? Promise.reject("Can't get all units. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ municipalityUnit: Unit[] }>),
        getUnit: (unitId: string) =>
            !authToken?.length
                ? Promise.reject("Can't get unit details. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<Unit>),
        createUnit: (data: UnitInput) =>
            !authToken?.length
                ? Promise.reject("Can't create unit. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units`,
                          1
                      ),
                      sendJSON(data, authToken, "POST")
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ municipalityUnitID: string }>),
        updateUnit: (data: UnitInput, unitId: string) =>
            !authToken?.length
                ? Promise.reject("Can't update unit details. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}`,
                          1
                      ),
                      sendJSON(data, authToken, "PUT")
                  ).then(ensureHttpSuccess),
        getUnitRestrictions: (unitId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get unit time restrictions. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/restrictions`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              homeTimeWindowRestrictions: TimeRestriction[];
                          }>
                      ),
        updateUnitRestrictions: (
            restrictions: TimeRestriction[],
            unitId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update unit time restrictions. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/restrictions`,
                          1
                      ),
                      sendJSON(
                          { homeTimeWindowRestrictions: restrictions },
                          authToken,
                          "PUT",
                          "application/json"
                      )
                  ).then(ensureHttpSuccess),
        getUnitReplacementWindows: (unitId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get replacement windows for unit. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/windowreplacementconfigurations`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<ReplacementWindowResponse>),
    };
};

export const ordererMethods = (municipality: string) => {
    const authToken = getAuthToken();

    return {
        getAllOrderers: (unitIds?: string[]) =>
            !authToken?.length
                ? Promise.reject("Can't get all orderers. Not authenticated")
                : fetch(
                      getUrlWithQueryString(
                          `${BASEURL}/municipality/${municipality}/orderers`,
                          {
                              fields: "FULL",
                              unitIds: unitIds?.join(",") || undefined,
                          },
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              userlist: Orderer[];
                          }>
                      ),
        getOrderer: (ordererId: string) =>
            !authToken?.length
                ? Promise.reject("Can't get orderer details. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/orderers/${ordererId}`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<Orderer>),
        deleteOrderer: (ordererId: string) =>
            !authToken?.length
                ? Promise.reject("Can't delete orderer. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/orderers/${ordererId}`,
                          1
                      ),
                      fetchJSON(authToken, "DELETE")
                  ).then(ensureHttpSuccess),
        createOrderer: (data: OrdererInput) =>
            !authToken?.length
                ? Promise.reject("Can't create orderer. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/orderers?`,
                          1
                      ),
                      sendJSON(data, authToken, "POST")
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ ordererID: string }>),
        updateOrderer: (data: OrdererInput, ordererId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update orderer details. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/orderers/${ordererId}`,
                          1
                      ),
                      sendJSON(data, authToken, "PUT")
                  ).then(ensureHttpSuccess),
    };
};

export const customerMethods = (municipality: string) => {
    const authToken = getAuthToken();

    return {
        getAllCustomers: (
            page: number,
            pageSize: number,
            unitIds?: string[],
            searchQuery?: string
        ) =>
            !authToken?.length
                ? Promise.reject("Can't get all customers. Not authenticated")
                : fetch(
                      getUrlWithQueryString(
                          `${BASEURL}/municipality/${municipality}/customers`,
                          {
                              fields: "FULL",
                              currentPage: page.toString(),
                              pageSize: pageSize.toString(),
                              unitIds: unitIds?.join(",") || undefined,
                              freeTextFilter:
                                  searchQuery?.toString() || undefined,
                          },
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              pagination: PaginationDTO;
                              userlist: CustomerDTO[];
                          }>
                      ),
        getCustomersByUnit: (
            unitId: string,
            page: number,
            pageSize: number,
            searchQuery?: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get customers by unit. Not authenticated"
                  )
                : fetch(
                      getUrlWithQueryString(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers`,
                          {
                              fields: "FULL",
                              currentPage: page.toString(),
                              pageSize: pageSize.toString(),
                              freeTextFilter:
                                  searchQuery?.toString() || undefined,
                          },
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              pagination: PaginationDTO;
                              userlist: CustomerDTO[];
                          }>
                      ),
        getCustomer: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get customer details. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}?fields=FULL`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<CustomerDTO>),
        deleteCustomer: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject("Can't delete customer. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}`,
                          1
                      ),
                      fetchJSON(authToken, "DELETE")
                  ).then(ensureHttpSuccess),
        createCustomer: (data: UpdateCustomerRequestBody, unitId: string) =>
            !authToken?.length
                ? Promise.reject("Can't create customer. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers`,
                          1
                      ),
                      sendJSON(data, authToken, "POST")
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ customerID: string }>),
        updateCustomer: (
            data: UpdateCustomerRequestBody,
            unitId: string,
            customerId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update customer details. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}`,
                          1
                      ),
                      sendJSON(data, authToken, "PUT")
                  ).then(ensureHttpSuccess),
        updateCustomerPaymentInfo: (
            paymentMethod: PaymentCodes,
            unitId: string,
            customerId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update customer payment info. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/payment`,
                          1
                      ),
                      sendJSON({ code: paymentMethod }, authToken, "PUT")
                  ).then(ensureHttpSuccess),
        createCustomerBilling: (
            data: UpdateCustomerBillingRequest,
            unitId: string,
            customerId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't create customer billing address. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/billingAddress`,
                          1
                      ),
                      sendJSON(data, authToken, "POST")
                  ).then(ensureHttpSuccess),
        updateCustomerBilling: (
            data: UpdateCustomerBillingRequest,
            unitId: string,
            customerId: string,
            addressId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update customer billing address. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/billingAddress/${addressId}`,
                          1
                      ),
                      sendJSON(data, authToken, "PUT")
                  ).then(ensureHttpSuccess),
        getCustomerOrders: (customerId: string) =>
            !authToken?.length
                ? Promise.reject("Can't get customer orders. Not authenticated")
                : fetch(
                      withoutCache(
                          `${BASEURL}/users/${customerId}/orders?api-version=v1`
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<GetCustomerOrdersDTO>),
        getCustomerRestriction: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get customer time restriction. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/hometimewindowrestrictions`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(
                          parseJSON<{
                              homeTimeWindowRestrictions: TimeRestriction[];
                          }>
                      ),
        getCustomerBilling: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get customer billing address. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/billingAddress?fields=FULL`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(async (data) => {
                          try {
                              return JSON.parse(
                                  await data.text()
                              ) as CustomerBillingDTO;
                          } catch {
                              return undefined;
                          }
                      }),
        getCustomerReplacementWindows: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't get replacement windows for customer. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/windowreplacementconfigurations`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<ReplacementWindowResponse>),
        updateCustomerRestrictions: (
            restrictions: TimeRestriction[],
            unitId: string,
            customerId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't update customer time restriction. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/hometimewindowrestrictions`,
                          1
                      ),
                      sendJSON(
                          { homeTimeWindowRestrictions: restrictions },
                          authToken,
                          "PUT",
                          "application/json"
                      )
                  ).then(ensureHttpSuccess),

        initializeMatkonto: (unitId: string, customerId: string) =>
            !authToken?.length
                ? Promise.reject("Can't initialize Matkonto. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/matkonto/init?clientId=${MATKONTO_CLIENTID}`,
                          1
                      ), // TODO change clentid to the one setup in Hybris
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ url: string }>),

        verifyMatkonto: (
            unitId: string,
            customerId: string,
            transactionCode: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't verify Matkonto payment. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/matkonto/verification?code=${transactionCode}`,
                          1
                      ),
                      fetchJSON(authToken)
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<Response>),
        deleteMatkontoCard: (
            unitId: string,
            customerId: string,
            tokenId: string
        ) =>
            !authToken?.length
                ? Promise.reject(
                      "Can't delete matkonto card. Not authenticated"
                  )
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/municipality/${municipality}/units/${unitId}/customers/${customerId}/paymenttoken?tokenId=${tokenId}`,
                          1
                      ),
                      sendJSON({}, authToken, "DELETE")
                  ).then(ensureHttpSuccess),
        cancelOrder: (userId: string, orderId: string) =>
            !authToken?.length
                ? Promise.reject("Can't cancel order. Not authenticated")
                : fetch(
                      getUrlWithApiVersion(
                          `${BASEURL}/users/${userId}/orders/${orderId}/cancel`,
                          1
                      ),
                      fetchJSON(authToken, "POST")
                  )
                      .then(ensureHttpSuccess)
                      .then(parseJSON<{ cancelOk: boolean }>),
    };
};
