import React, { useRef } from "react";
import { animated } from "react-spring";
import styled, { css } from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import { byName } from "../common/helpers/sortFunctions";
import mixins from "../common/mixins";
import { ListOption } from "../common/types";
import { ValidationResult } from "../common/validations";
import { fontSize, space } from "../common/variables";
import { useAnimatedDropdownState } from "../hooks/useAnimatedDropdownState";
import SelectableListItem from "./SelectableListItem";

type Props<TOption extends ListOption> =
    | {
          // Available options to select
          data: TOption[];
          placeholder?: string;
          required?: boolean;
          emptyText?: string;
          validation?: ValidationResult;
      } & (
          | {
                type: "single";
                // Selected option
                value: TOption | undefined;
                getValueFormatted: (value: TOption) => string;
                setChoice: (option: TOption) => void;
            }
          | {
                type: "multiple";
                // Selected options
                value: TOption[] | undefined;
                getValueFormatted: (value: TOption[]) => string;
                setChoice: (options: TOption[]) => void;
            }
      );

type ValidationState = "valid" | "invalid" | "not-decided";

const DropdownList = <TOption extends ListOption>(props: Props<TOption>) => {
    const { data, placeholder, validation, required, emptyText } = props;

    const ref = useRef<HTMLDivElement & any>();

    const { isExpanded, animProps, toggle, collapse } =
        useAnimatedDropdownState(ref);

    function handleOptionChoice(option: TOption) {
        if (props.type === "single") {
            collapse();
            props.setChoice(option);
        }

        if (props.type === "multiple") {
            const alreadySelected = props.value?.find(
                (item) => item.id === option.id
            );

            if (alreadySelected) {
                const optionsNotSelected =
                    props.value?.filter((item) => item.id !== option.id) || [];

                props.setChoice(optionsNotSelected);
            } else {
                const newOptions = [...(props.value || []), option];
                props.setChoice(newOptions);
            }
        }
    }

    const valueIsEmpty =
        props.type === "single" ? !props.value : !props.value?.length;

    const selectedValueFormatted =
        props.type === "single"
            ? props.value !== undefined && props.getValueFormatted(props.value)
            : props.value !== undefined &&
              props.value.length > 0 &&
              props.getValueFormatted(props.value);

    let validationState: ValidationState = "not-decided";
    if (validation) {
        if (validation.isValid) {
            validationState = "valid";
        } else {
            validationState = "invalid";
        }
    }

    return (
        <Wrapper ref={ref} status={validationState} isExpanded={isExpanded}>
            {placeholder && (
                <Placeholder
                    retracted={
                        props.type === "multiple"
                            ? !!props.value?.length
                            : !!props.value
                    }>
                    {`${placeholder}`}
                    {required && ` *`}
                </Placeholder>
            )}
            <Header onClick={() => toggle()} type="button">
                {selectedValueFormatted}
                <Arrow>
                    <ArrowContainer expanded={isExpanded ? 1 : 0}>
                        {assets.angle}
                    </ArrowContainer>
                </Arrow>
                {validation && validationState === "invalid" && (
                    <Error>{validation.message}</Error>
                )}

                {/* Red round icon  */}
                {validationState === "invalid" && (
                    <ValidationIcon valid={false}>
                        {assets.exclamation}
                    </ValidationIcon>
                )}

                {/* Green round icon */}
                {!valueIsEmpty /* dont set green mark when there is no value yet */ &&
                    validationState === "valid" && (
                        <ValidationIcon valid>
                            {assets.checkmark}
                        </ValidationIcon>
                    )}
            </Header>

            {isExpanded && (
                <Panel expanded={1} style={animProps}>
                    <PanelContainer>
                        {data.length ? (
                            data.sort(byName).map((option) => {
                                const selected =
                                    props.type === "multiple"
                                        ? props.value?.find(
                                              (item) => item.id === option.id
                                          )
                                        : props.value &&
                                          props.value.id === option.id;

                                return (
                                    <SelectableListItem
                                        key={option.id}
                                        selected={!!selected}
                                        onClick={() =>
                                            handleOptionChoice(option)
                                        }
                                        title={option.name}
                                        style={{ width: "50%" }}
                                    />
                                );
                            })
                        ) : (
                            <EmptyText>{emptyText}</EmptyText>
                        )}
                    </PanelContainer>
                </Panel>
            )}
        </Wrapper>
    );
};

export default DropdownList;

const Wrapper = styled.div<{
    status: ValidationState;
    readOnly?: boolean;
    isExpanded: boolean;
}>`
    display: flex;
    height: 50px;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    background-color: ${colors.border};
    border-radius: ${mixins.borderRadius.default};
    border-width: 1px;
    border-style: solid;
    z-index: ${({ isExpanded }) => (isExpanded ? 2 : 1)};

    ${({ status, readOnly }) => {
        if (readOnly) {
            return css`
                border-color: transparent;
            `;
        }

        if (status === "invalid") {
            return css`
                border-color: ${colors.red};
            `;
        } else {
            return css`
                border-color: transparent;

                &:hover {
                    border-color: ${colors.grayMid};
                }
            `;
        }
    }};
`;

const Placeholder = styled.span`
    font-size: ${fontSize.small};
    position: absolute;
    left: ${space.small};
    line-height: 10px;
    color: ${colors.grayDarker};
    pointer-events: none;
    top: 50%;
    transform: translateY(-150%);
    font-size: ${fontSize.xSmall};
    transition: transform ${mixins.transitions.default},
        font-size ${mixins.transitions.default};

    ${({ retracted }: { retracted?: boolean }) => {
        if (retracted) {
            return css`
                top: 50%;
                transform: translateY(-150%);
                font-size: ${fontSize.xSmall};
            `;
        } else {
            return css`
                top: 50%;
                transform: translateY(-50%);
                font-size: ${fontSize.small};
            `;
        }
    }}
`;

const Header = styled.button<{ readOnly?: boolean }>`
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
    border: none;
    font-size: ${fontSize.small};
    padding: ${space.small} ${space.small} 0 ${space.small};
    outline: none;
    text-align: left;
    cursor: ${({ readOnly }) => (readOnly ? "not-allowed" : "default")};
`;

const Arrow = styled.span`
    position: absolute;
    height: 14px;
    width: 14px;
    top: 50%;
    fill: ${colors.grayDarker};
    transform: translateY(-50%);
    right: ${space.small};
`;

const ArrowContainer = styled.span`
    display: block;
    height: 100%;
    width: 100%;
    transition: transform ${mixins.transitions.default};

    ${({ expanded }: { expanded?: number }) =>
        expanded
            ? css`
                  transform: rotate(90deg);
              `
            : css`
                  transform: rotate(-90deg);
              `};
`;

const Panel = styled(animated.div)<{ expanded?: number }>`
    position: absolute;
    display: flex;
    max-height: 340px;
    flex-direction: column;
    top: 65px;
    left: 0;
    width: 100%;
    margin-bottom: ${space.padding};
    background-color: ${colors.white};
    border-radius: ${mixins.borderRadius.default};
    box-shadow: ${mixins.boxShadow.bottomAngle};
    border: 1px solid ${colors.border};
    pointer-events: ${({ expanded }: { expanded?: number }) =>
        expanded ? "auto" : "none"};

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
    }

    &:before {
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.border};
    }

    &:after {
        top: -8px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.white};
    }
`.withComponent(animated.div);

const PanelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${space.medium};
    overflow: auto;
`;

const Error = styled.span`
    font-size: ${fontSize.xSmall};
    position: absolute;
    left: 0;
    bottom: -18px;
    color: ${colors.red};
    pointer-events: none;
`;

const ValidationIcon = styled.span<{ valid?: boolean }>`
    height: 16px;
    width: 16px;
    position: absolute;
    right: ${space.xxLarge};
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    fill: ${({ valid }) => (valid ? colors.green : colors.red)};
`;

const EmptyText = styled.span`
    color: ${colors.grayDark};
`;
