import React, { useRef } from "react";
import { animated } from "react-spring";
import styled, { CSSObject, css } from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import { byName } from "../common/helpers/sortFunctions";
import mixins from "../common/mixins";
import { ListOption } from "../common/types";
import { fontSize, space } from "../common/variables";
import { useAnimatedDropdownState } from "../hooks/useAnimatedDropdownState";
import Button from "./Button";
import SelectableListItem from "./SelectableListItem";

interface Props {
    data: ListOption[];
    selectedData: ListOption[];
    placeholder: string;
    itemName: string;
    expandedCallback?: (expanded: boolean) => void;
    clearFilter: () => void;
    setChoice: (option: ListOption) => void;
    panelStyle?: CSSObject;
}

const DropdownFilter = ({
    data,
    selectedData,
    placeholder,
    setChoice,
    expandedCallback,
    itemName,
    panelStyle,
    clearFilter,
}: Props) => {
    const ref = useRef<HTMLDivElement & any>();
    const { isExpanded, animProps, toggle } = useAnimatedDropdownState(
        ref,
        expandedCallback
    );

    return (
        <Wrapper ref={ref}>
            <Header
                active={selectedData.length > 0}
                type="button"
                onClick={() => {
                    toggle();
                }}>
                <Placeholder>
                    {selectedData.length
                        ? `${itemName} (${selectedData.length})`
                        : placeholder}
                </Placeholder>
                <Arrow>
                    <ArrowContainer expanded={isExpanded ? true : undefined}>
                        {assets.angle}
                    </ArrowContainer>
                </Arrow>
            </Header>
            <Panel
                expanded={isExpanded ? 1 : 0}
                panelstyle={panelStyle}
                style={animProps}>
                <PanelContainer>
                    {data.sort(byName).map((option) => {
                        const selected = !!selectedData.find(
                            (sel) => sel.id === option.id
                        );

                        return (
                            <SelectableListItem
                                key={option.id}
                                selected={selected}
                                onClick={() => setChoice(option)}
                                title={option.name}
                            />
                        );
                    })}
                    <PanelButton>
                        <Button
                            size="small"
                            wrapperStyle={{ width: "100%" }}
                            containerStyle={{ width: "100%" }}
                            theme="inverted"
                            onClick={clearFilter}
                            disabled={!selectedData.length}
                            title={
                                selectedData.length
                                    ? `Rensa filter (${selectedData.length})`
                                    : `Rensa filter`
                            }
                        />
                    </PanelButton>
                </PanelContainer>
            </Panel>
        </Wrapper>
    );
};

export default DropdownFilter;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    z-index: 2;
`;

const Placeholder = styled.span`
    display: inline-block;
    font-size: ${fontSize.small};
`;

const Header = styled.button`
    width: 101px;
    height: 100%;
    background-color: transparent;
    position: relative;
    border: none;
    font-size: ${fontSize.small};
    outline: none;
    text-align: left;
    color: ${colors.grayDarker};
    fill: ${colors.grayDarker};
    padding: 0;
    cursor: pointer;

    ${({ active }: { active?: boolean }) =>
        active
            ? css`
                  color: ${colors.green};
                  fill: ${colors.green};
              `
            : css`
                  color: ${colors.grayDarker};
                  fill: ${colors.grayDarker};

                  &:hover {
                      color: ${colors.black};
                      fill: ${colors.black};
                  }
              `}
`;

const Arrow = styled.span`
    display: inline-block;
    height: 12px;
    width: 12px;
    position: relative;
    top: 2px;
    margin-left: ${space.xSmall};
`;

const ArrowContainer = styled.span`
    display: block;
    height: 100%;
    width: 100%;
    transition: transform ${mixins.transitions.default};

    ${({ expanded }: { expanded?: boolean }) =>
        expanded
            ? css`
                  transform: rotate(90deg);
              `
            : css`
                  transform: rotate(-90deg);
              `};
`;

const Panel = styled(animated.div)<{
    expanded?: number;
    panelstyle?: CSSObject;
}>`
    position: absolute;
    display: flex;
    max-height: 340px;
    flex-direction: column;
    width: 100%;
    top: 36px;
    left: -95%;
    min-width: 270px;
    margin-bottom: ${space.padding};
    background-color: ${colors.white};
    border-radius: ${mixins.borderRadius.default};
    box-shadow: ${mixins.boxShadow.bottomAngle};
    border: 1px solid ${colors.border};
    pointer-events: ${({ expanded }: { expanded?: number }) =>
        expanded ? "auto" : "none"};
    ${({ panelstyle }: { panelstyle?: CSSObject }) =>
        css`
            ${panelstyle}
        `};

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
    }

    &:before {
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.border};
    }

    &:after {
        top: -8px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.white};
    }
`.withComponent(animated.div);

const PanelContainer = styled.div`
    padding: ${space.medium};
    overflow: auto;
`;

const PanelButton = styled.div`
    margin-top: ${space.small};
`;
