import { appConfig } from "../appConfig";
import { ListOption } from "../common/types";
import { useLocalStorage } from "./useLocalStorage";

type FilterType = "customers" | "orderers";

const useFilters = (
    type: FilterType,
    municipalityId: string,
    userId?: string
) => {
    const [customerFilters, setCustomerFilters] = useLocalStorage(
        `${appConfig.MODE}.${type}.${userId}.${municipalityId}.filter`,
        (value) => {
            return (
                (value?.split(",").map((el) => {
                    return { id: el };
                }) as ListOption<string, string, any>[]) || []
            );
        },
        (value) => value.map((el) => el.id).join(",") || null
    );

    return [customerFilters, setCustomerFilters] as const;
};

export default useFilters;
