import React from "react";
import styled, { css } from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { fontSize, space } from "../common/variables";

const SelectableListItem = ({
    onClick,
    selected,
    title,
    large,
    style,
}: {
    onClick: () => void;
    selected?: boolean;
    title: string;
    large?: boolean;
    style?: React.CSSProperties;
}) => {
    return (
        <Option selected={selected} large={large} style={style}>
            <OptionContainer onClick={onClick} large={large} type="button">
                <Marker>
                    <Icon selected={selected}>{assets.checkmark}</Icon>
                    <Dot selected={selected} />
                </Marker>
                <Title>{title}</Title>
            </OptionContainer>
        </Option>
    );
};

export default SelectableListItem;

const Title = styled.span`
    font-size: ${fontSize.small};
    line-height: ${fontSize.medium};
    transition: color ${mixins.transitions.default};
`;

const Option = styled.div<{ selected?: boolean; large?: boolean }>`
    min-height: 30px;
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    ${Title} {
        color: ${({ selected }) =>
            selected ? colors.black : colors.grayDarker};
    }

    ${({ large }) =>
        large &&
        css`
            width: 100%;
            height: 60px;
            border-top: 1px solid ${colors.border};

            &:hover {
                box-shadow: ${mixins.boxShadow.small};
                z-index: 1;
            }

            &:first-of-type {
                border-top: none;
            }
        `}
`;

const OptionContainer = styled.button<{ large?: boolean }>`
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: center;
    cursor: pointer;
    overflow: visible;
    justify-content: flex-start;
    text-align: left;
    &:hover {
        ${Title} {
            color: ${colors.black};
        }
    }

    ${({ large }) =>
        large &&
        css`
            width: 100%;
            height: 100%;
            padding: 0 ${space.medium};
        `}
`;

const Marker = styled.div`
    display: flex;
    width: 25px;
    height: 100%;
    position: relative;
    flex-shrink: 0;
`;

const Dot = styled.span`
    position: absolute;
    top: 50%;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: ${colors.grayMid};
    transition: transform ${mixins.transitions.default};

    transform: ${({ selected }: { selected?: boolean }) =>
        selected ? "translateY(-50%) scale(0)" : "translateY(-50%) scale(1)"};
`;

const Icon = styled.span`
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: -3px;
    fill: ${colors.green};
    transition: transform ${mixins.transitions.default};

    transform: ${({ selected }: { selected?: boolean }) =>
        selected ? "translateY(-50%) scale(1)" : "translateY(-50%) scale(0)"};
`;
