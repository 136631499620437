import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListOption } from "../../common/types";
import Button from "../../components/Button";
import Container from "../../components/Container";
import DropdownFilter from "../../components/DropdownFilter";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Table from "../../components/Table/Table";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import useFilters from "../../hooks/useFilters";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useAllOrderersQuery } from "../../queries/useAllOrderersQuery";
import { filterOrderersAuthorizedUnits } from "./OrdererView.utils";
import { OrdererListingItem } from "./ordererTypes";

const columnHelper = createColumnHelper<OrdererListingItem>();
const columns = [
    columnHelper.accessor(
        (row) => row.orderer.firstName + " " + row.orderer.lastName,
        {
            id: "name",
            header: () => "Namn",
            cell: (props) => props.getValue(),
            meta: {
                getCellContext: () => {
                    return {
                        length: 3,
                    };
                },
            },
        }
    ),
    columnHelper.accessor((row) => row.orderer.municipalEmail, {
        id: "email",
        header: () => "E-postadress",
        enableSorting: false,
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    length: 3,
                };
            },
        },
    }),
    columnHelper.accessor(
        (row) =>
            row.authUnits?.length
                ? `${row.authUnits
                      ?.filter((o, i) => i < 3)
                      .map((o) => o.name)
                      .join(", ")}`
                : "Inga enheter",
        {
            id: "units",
            header: () => "Enhet",
            enableSorting: false,
            cell: (props) => props.getValue(),
            meta: {
                getCellContext: () => {
                    return {
                        length: 3,
                    };
                },
            },
        }
    ),
];

const OrdererListingView = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const municipalityId = useSelectedMunicipalityId();
    const { authUserData } = useContext(AuthenticatedUserContext);

    const allUnitsQuery = useAllUnitsQuery();

    const [unitFilters, setUnitFilters] = useFilters(
        "orderers",
        municipalityId,
        authUserData?.identityId
    );

    const [filterExpanded, setFilterExpanded] = useState(false);

    const allOrderersQuery = useAllOrderersQuery(
        unitFilters?.map(({ id }) => id)
    );

    const data = useMemo(
        () =>
            allOrderersQuery.data &&
            allUnitsQuery.data &&
            filterOrderersAuthorizedUnits(
                allOrderersQuery.data,
                allUnitsQuery.data
            ),
        [allOrderersQuery.data, allUnitsQuery.data]
    );

    const table = useReactTable({
        data: data || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const setFilterChoice = (selectedFilter: ListOption) => {
        const alreadySelected = unitFilters.find(
            (filter) => filter.id === selectedFilter.id
        );

        if (alreadySelected) {
            const updatedFilters = [...unitFilters].filter(
                (filter) => filter.id !== selectedFilter.id
            );
            setUnitFilters(updatedFilters);
        } else {
            setUnitFilters([...unitFilters, selectedFilter]);
        }
    };

    return (
        <Container>
            <SubHeader
                title="Beställare"
                leftContent={
                    allUnitsQuery.isSuccess &&
                    !!allUnitsQuery.data.length && (
                        <DropdownFilter
                            data={allUnitsQuery.data.map((unit) => ({
                                id: unit.code,
                                name: unit.name,
                            }))}
                            selectedData={unitFilters}
                            clearFilter={() => setUnitFilters([])}
                            expandedCallback={(expanded) =>
                                setFilterExpanded(expanded)
                            }
                            placeholder="Alla enheter"
                            itemName="Enheter"
                            setChoice={setFilterChoice}
                        />
                    )
                }
                rightContent={
                    <Button
                        size="small"
                        onClick={() => {
                            navigate(pathname + "/skapa-ny");
                        }}
                        title="Skapa ny"
                    />
                }
            />
            <Plate
                spaceBottom={true}
                overScroll
                faded={filterExpanded || allOrderersQuery.isFetching}>
                {allUnitsQuery.isLoading || allOrderersQuery.isLoading ? (
                    <Loader />
                ) : !allOrderersQuery.data?.length ? (
                    <EmptyState text="Det finns inga beställare." />
                ) : (
                    <Table
                        table={table}
                        getLinkTo={(data) => pathname + "/" + data.orderer.uid}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default OrdererListingView;
